

.shipment_componentContainer:last-child {
    padding: 0px !important;
}

.shipment_cardContent {
    padding: 0px !important;
}
.shipment_form_content{
    padding: 30px 100px 30px 100px;
    min-height: 500px;
}
.shipment_form_content_res{
    padding: 20px 20px 20px 20px;

}
.shipment_form_heder{
    color: var(--dark, #1E232C);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: left;
    align-items: center;
}
.shipment_next_button{
    border-radius: 38px !important;
    background: #BDBDBD !important;
    color: #F7F8F9 !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    width: 90% !important;

}
.shipment_next_button_filled{
    border-radius: 38px !important;
    background: #F06B24 !important;
    color: #F7F8F9 !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    width: 90% !important;
}

.shipment_submit_button_gray{
    border-radius: 38px !important;
    background: #BDBDBD !important;
    color: #F7F8F9 !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    width: 90% !important;
    margin-top: 45px !important;

}
.shipment_submit_button{
    border-radius: 38px !important;
    background: #F06B24 !important;
    color: #F7F8F9 !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    width: 90% !important;
    margin-top: 45px !important;

}
.shipment_width{
    width: 100% !important;
}
.shipment_label{
    text-align: left !important;
    color: rgba(0, 0, 0, 0.6) !important;
    margin-bottom: 10px;
}
.shipment_radio_align
{
    justify-content: center !important;
}

.custom_radio {
    border: 1px solid #ccc;
    padding: 4px 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 5px;
  }
  
  .custom_radio span  {
    padding: 0px !important;
  }
  .custom_radio span span {
    margin-right: 5px;  }
  
.shipment_back_button_res{
    padding: 2px 5px 0px 5px;
    border-radius: 12px;
    border: 1px solid var(--border, #E8ECF4);
    background: var(--white, #FFF);
    cursor: pointer;
    margin-right: 15px;
}
.shipment_back_button_res svg
{
    font-size: 20px;
}
.shipment_back_button
{
    margin-right: 12px;
    margin-top: 8px;
    cursor: pointer;
}
.shipment_border_orange{
    border-bottom: 4px solid #F06B24;
    margin-top: 10px;
}
.shipment_border_gray{
    border-bottom: 4px solid #D9D9D9;
    margin-top: 10px;
}
.shipment_error{
    color: #e74c3c;
    font-size: 14px;
    text-align: left;
    float: left;
}
