.affiliationProvisioning_container {
    /* border: 1px solid #e2e2e2; */
    box-shadow: none !important;
    margin-bottom: 10px;
}

.affiliationProvisioning_headingConatiner {
    padding-top: 22px;
    padding-left: 20px;
    display: flex;
}

.affiliationProvisioning_title {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    width: 60%;
}

.affiliationProvisioning_row {
    margin-top: 4px !important;
    display: flex;
    justify-content: space-between;
}

.affiliationProvisioning_value {
    text-align: left;
    font-weight: 600;
    font-size: 16px !important;
    letter-spacing: 0.42px;
    color: #000000;
}

.affiliationProvisioning_label {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
}

.affiliationProvisioning_row {
    margin-top: 4px !important;
    display: flex;
    justify-content: space-between;
}

.affiliationProvisioning_label {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
}

.affiliationProvisioning_mobileNumbersDialogBox {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
    width: 60% auto;
    display: flex;
    flex: 1;
}