.GenericTable__headRow {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

.GenericTable__headCell {
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold !important;
}

.GenericTable__container {
    overflow-x: hidden;
}

.GenericTable__cardContent {
    padding: 0px;
}

.GenericTable__tableDataRow {
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F5F5F5;
}

.GenericTable__tableCellData {
    text-align: center;
    color: #000000;
}

.GenericTable__tableCard {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.GenericTable__textHeadingConatiner {
    font-size: medium;
    font-weight: bold;
    padding-top: 22px;
    padding-left: 20px;
    padding-bottom: 16px;
    display: flex;
}

.GenericTable__tableTitle {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    text-align: left;
    width: 60%;
}

@media (max-width:1024px) {
    .GenericTable__container {
        overflow-x: scroll;
    }
}
