.root {
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1;
  margin: 20px 20px 20px 230px;
  width: calc(100% - 230px);
  transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.content_res {
  flex: 1;
  margin: 0px;
  width: 100%;
  transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.content_res,
.content {
  overflow-y: auto;
  height: 100vh;
}

.header {
  background-color: #ffffff;
  height: 58px;
  margin-bottom: 20px;
  border-radius: 19.367px;
}

.header_responsive {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  background-color: #ffffff;
  height: 55px;
  margin-bottom: 20px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 15px 1px #3333;
}

.header_button {
  margin-left: 15px !important;
}

.img {
  height: 35px;
  margin-right: 18px;
}

.date {
  float: left;
  padding-top: 18px;
  padding-left: 53px;
  color: #4d4d4d;
  font-size: 15px;
}

.welcome {
  float: right;
  padding-right: 70px;
  padding-top: 18px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 15px;
}

.collapsed {
  margin: 20px 20px 20px 130px !important;
  width: calc(100% - 130px) !important;
  transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}