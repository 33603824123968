.devicesTable__container {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.devicesTable__headingConatiner {
    padding-top: 22px;
    padding-left: 20px;
    display: flex;
}

.devicesTable__title {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    width: 60%;
}