.analyse_container {
    padding: 0px 10px 20px 10px;
}

.analyse_top_bar_align {
    align-items: center;
    text-align: left;
}

.analyse_top_bar_title {
    color: #06152B;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.select_branch {
    background: #fff;
    border-radius: 8px;
    padding: 4px 10px 4px 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);
}

.analyse_date_filter_align {
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    padding: 4px;
}

.analyse_date_filter_button_active {
    color: #F06B24;
    background: #FEF6F2 !important;
    border-radius: 18px;
}

.analyse_date_filter_button {
    color: #06152B !important;
    background: #fff !important;
    border-radius: 18px;
}

.analyse_total_card {
    display: flex;
    min-height: 196px;
    background: #fff;
    padding: 25px 20px 25px 20px;
    border-radius: 10px;
    box-shadow: 0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.analyse_total_card .analyse_total_icon_bg {
    width: 60.626px;
    height: 60.626px;
    background: #ECF0F4 !important;
    border-radius: 50%;
    margin: 10px 0 10px 0px;
}

.analyse_total_icon_bg svg {
    padding: 18px;
}

.analyse_card_icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.analyse_card_title {
    color: #202536;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.508px;
}

.analyse_card_count {
    color: #202536;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 42.01px;

}

.analyse_driver_card {
    border-radius: 15.938px;
    background: #FFF;
    box-shadow: 0px 3.54px 3.54px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    text-align: left;
    min-height: 204px;
}

.analyse_driver_title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.analyse_driver_count {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 12px;
}

.analyse_driver_color_icon {
    min-width: 20px !important;
}

.analyse_driver_color_icon_svg_color_high {
    font-size: 10px !important;
    color: #2D9CDB;
}

.analyse_driver_color_icon_svg_obeyed_alert {
    font-size: 10px !important;
    color: #03A89E;
}


.analyse_driver_color_icon_svg_color_low {
    font-size: 10px !important;
    color: #EB5757;
}

.analyse_driver_text span {
    color: #67707E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.analyse_driver_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.analyse_driver_alert_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.analyse_driver_alert_box .box {
    display: flex;
    height: 40px;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    width: 40%;
    box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
}

.analyse_download_report_btn {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.mt_15 {
    margin-top: 15px;
}

.mt_10 {
    margin-top: 10px;
}


.branch_box {
    box-shadow: 0px 3.54px 3.54px 0px rgba(0, 0, 0, 0.05) !important;
}


.branch_box,
.heading {
    padding: 10px;
    text-align: left
}

.branch_box .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.branch_box .view_All_button {
    float: right;
}

.align_items_center {
    align-items: center;
}

.performance_drp_color {
    color: #EB5757
}

.table_component_container:last-child {
    padding: 0px 10px 20px 10px;
}

.analyse_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh
}

.low_adherence {
    color: #EB5757;
    display: flex;
    align-items: center;

}

.low_adherence svg {
    margin-left: 10px !important;
    font-size: 20px !important;
}

.shipment_details_app_status_healthy {
    fill: #03A89E;
    color: #03A89E;

}

.shipment_details_app_status_unhealthy {
    fill: #EB5757;
    color: #EB5757;

}
