.reportsPage_componentContainer {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.reportsPage_componentContainer:last-child {
    margin-bottom: 20px !important;
}

.title {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    padding-bottom: 50px;
}

.reportsPage_parentContainer {
    margin-left: 20px !important;
    margin-right: 20px !important;
    background: white !important;
    padding: 20px !important;
    text-align: left !important;
}
