.modalRoot {
  overflow: scroll;
  border-color: white;
}

.modalCloseIcon {
  float: right;
  padding-right: 5px;
  padding-top: 5px;
}

.title {
  font-weight: bolder;
}

.modalBody {
  margin-top: 40px;
  width: 800px;
  height: 450px;
}

.license {
  border-radius: 3px;
  margin: 0 auto;
  padding-top: 20px;
  width: 90%;
}

.dlImages{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

.dlImage {
  width: 340px;
  height: 200px;
}

.covs, .driverInfo {
  display: flex;
  justify-content: space-between;
}

.actions {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  width: 500px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.verifyButton {
  background-color: rgb(40, 92, 214);
  color: #fff;
  font-weight: bold;
}

.deleteButton {
  background-color: rgb(200, 51, 51);
  color: #fff;
  font-weight: bold;
}
