.root {
    background-color: white;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
    border-radius: 15.938px;
}

.header {
    margin: 0;
    position: relative;
    top: 50%;
    border: 1px;
    background-color: white;
}

.submitButton {
    margin-top: 8px !important;
    margin-left: 20px !important;
}

.textBox {
    font-weight: bold !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
    margin-top: 0px !important;
}

.errorMessage {
    text-align: center !important;
}
