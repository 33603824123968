.shipment_componentContainer:last-child {
    padding: 20px 10px 20px 10px;
}

.shipment_tableCard {
    margin-bottom: 10px;
}

.shipment_textHeadingConatiner {
    font-size: medium;
    font-weight: bold;
    padding-top: 22px;
    padding-left: 20px;
    padding-bottom: 16px;
    display: flex;
}

.shipment_tableTitle {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    text-align: left;
    width: 90%;
}

.shipment_cardContent {
    padding: 0px;
}

.shipment_count_card {
    display: flex;
    align-items: center;
    min-height: 70px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.05);
}

.shipment_count_card .shipment_count_icon_bg {
    width: 44.626px;
    height: 44.626px;
    background: #ECF0F4 !important;
    border-radius: 50%;
}

.shipment_count_card_active {
    display: flex;
    align-items: center;
    min-height: 70px;
    border: 1.5px solid #F06B24;
    background: #F4E6DE;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.05);
}

.shipment_count_card_active .shipment_count_icon_bg {
    width: 44.626px;
    height: 44.626px;
    background: #F06B24 !important;
    border-radius: 50%;
}

.shipment_count_card_active .shipment_count_icon_bg svg {
    color: #fff !important;
}

.shipment_count_icon_active {
    background: #F06B24;
}

.shipment_count_icon_bg svg {
    padding: 10px 0px;
    width: 45px;
}


.shipment_count {
    color: #202536;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.149px;
    text-align: left;
    margin-left: 10px;
}

.shipment_count_text {
    color: #202536;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.shipment_count_box {
    padding-left: 10px;
    padding-right: 10px;
}

.shipment_title_align {
    text-align: left;
}

.shipment_top_bar_text {
    color: #06152B;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.shipment_buttons_align {
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    padding: 4px;
}

.shipment_top_bar {
    padding: 0px 10px 20px 10px;
}

.shipment_top_bar_refresh {
    background: #ffff !important;
    font-size: 16px;
    border-radius: 20px !important;
    min-width: 125px !important;
    float: right;
}

.shipment_top_bar_button_active {
    color: #F06B24;
    background: #FEF6F2 !important;
    border-radius: 18px;
}

.shipment_top_bar_button {
    color: #06152B !important;
    background: #fff !important;
    border-radius: 18px;
}

.shipment_top_bar_search {
    padding: 4px 10px 4px 10px;
    background: #fff;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
}

.shipment_search_filter_btn {
    cursor: pointer;
}

.shipment_filter_box {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
}

.shipment_filter_box_title {
    color: var(--light-version-text, #06152B);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.shipment_filter_box_button_ml {
    margin-left: 8px !important;
}

.shipment_top_bar_align {
    align-items: center;
}

.shipment_details_th {
    padding: 4px !important;
    font-weight: 700 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.shipment_details_tr {
    padding: 4px !important;
}

.shipment_details_menu_ul {
    padding: 10px !important;
}

.shipment_details_menu_li {
    font-size: 16px;
    border-radius: 5.551px !important;
    background: #ECF0F4 !important;
    margin: 8px !important;
}

.shipment_details_menu_li svg {
    font-size: 18px;
    margin-right: 8px;
}

.shipment_details_active {
    color: #F06B24 !important;
}

.shipment_details_status_tracked {
    fill: #03A89E;
    border-radius: 50%;
    height: 12px !important;
    width: 12px !important;
    background: #e2e8f047;
    color: #03A89E;
    padding: 2px;
}

.shipment_details_status_intermittent {
    fill: #ffbf00;
    border-radius: 50%;
    height: 12px !important;
    width: 12px !important;
    background: #f5e6e666;
    color: #ffbf00;
    padding: 2px;
}


.shipment_details_status_not_tracked {
    fill: #EB5757;
    border-radius: 50%;
    height: 12px !important;
    width: 12px !important;
    background: #f5e6e666;
    color: #EB5757;
    padding: 2px;
}


.shipment_details_status_not_applicable {
    fill: #A9A9A9;
    border-radius: 50%;
    height: 12px !important;
    width: 12px !important;
    background: #FFFFFF;
    color: #A9A9A9;
    padding: 2px;
}

.shipment_details_progress_bar {
    color: #2D9CDB;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.shipment_details_progress_bar_100 {
    color: #03A89E;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.shipment_details_app_status_healthy {
    fill: #03A89E;
    color: #03A89E;

}

.shipment_details_app_status_unhealthy {
    fill: #EB5757;
    color: #EB5757;

}

.shipment_details_tr_center {
    text-align: center !important;
}

.shipment_details_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.shipment_subscription_message {
    font-size: larger;
    font-weight: 600;
}